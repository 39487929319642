
import api from '@/api/account'
import departmentApi from '@/api/department'
import { getToken, setToken, removeToken } from '@/utils/auth'
import router from '@/router'

const state = {
  token: getToken(),
  userData: null,
  current_user: {},
  permissions: [],
  departmentIds:'',
}

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_CURRENT_USER: (state, user) => {
    state.userData = user
  },
  SET_PERMISSIONS: (state, permissions) => {
    state.permissions = permissions
  },
  SET_DEPARTMENT_IDS: (state, departmentIds) => {
    state.departmentIds = departmentIds
  }
}

const actions = {
  // user login
  login({ commit }, userInfo) {
    const { username, password, remember } = userInfo
    return new Promise((resolve, reject) => {
      api.Login({ username: username.trim(), password: password, remember: remember }).then(response => {
        const { token } = response
        commit('SET_TOKEN', token)
        setToken(token)
       
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },
  // get user info
  getInfo({ commit, state }) {
    return new Promise((resolve, reject) => {
      api.GetInfo(state.token).then(response => {
        const { data } = response

        if (!data) {
          reject('Verification failed, please Login again.')
        }
        departmentApi.GetTreeWithAccount({ department_id: 0 }).then(res=>{
          if(res.data.length>0){
            let departmentIds=   res.data.map(item=>item.id).join(',')
            localStorage.setItem('departmentIds',departmentIds)
            commit('SET_DEPARTMENT_IDS', departmentIds)
          }
          commit('SET_CURRENT_USER', data)
        resolve(data)
        })
       
      }).catch(error => {
        reject(error)
      })
    })
  },

  // user logout
  logout({ commit }) {
    return new Promise((resolve, reject) => {
      api.Logout().then(() => {
        commit('SET_TOKEN', '')
        commit('SET_CURRENT_USER', null)
        console.log(1111)
        removeToken()
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },

  // remove token
  resetToken({ commit }) {
    return new Promise(resolve => {
      commit('SET_TOKEN', '')
      commit('SET_CURRENT_USER', null)
      removeToken()
      resolve()
    })
  },

  /* eslint-disable */
  // Dynamically modify permissions 动态修改权限
  changeRoles({ commit, dispatch }, role) {

    return new Promise(async resolve => {
      const token = role + '-token'

      commit('SET_TOKEN', token)
      setToken(token)

      const { roles } = await dispatch('getInfo')

      // generate accessible routes map based on roles
      const accessRoutes = await dispatch('permission/generateRoutes', roles, { root: true })

      // dynamically add accessible routes
      router.addRoutes(accessRoutes)

      resolve()
    })
  },

  // 查询当前用户的权限
  getPermissions({ commit }) {
    return new Promise(resolve => {
      api.GetAuthList().then(res => {
        commit('SET_PERMISSIONS', res.data)
        resolve(res.data)
      })
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
