import request from '@/utils/request'
const url = '/front/Project_department/'
const api = {
  // Query1(data) {
  //   return request({
  //     url: url + 'Query',
  //     method: 'post',
  //     data
  //   })
  // },
  GetTree(data) {
    return request({
      url: url + 'getTree',
      method: 'post',
      data
    })
  },
  GetTreeWithAccount(data) {
    return request({
      url: url + 'getTreeWithAccount',
      method: 'post',
      data
    })
  },
  // All() {
  //   return request({
  //     url: url + 'getAll',
  //     method: 'post'
  //   })
  // },
  Update(data) {
    return request({
      url: url + 'save',
      method: 'post',
      data
    })
  },
  Create(data) {
    return request({
      url: url + 'save',
      method: 'post',
      data
    })
  },
  Fetch(id) {
    return request({
      url: url + 'get',
      method: 'get',
      params: { id }
    })
  },
  Refund(id) {
    return request({
      url: url + 'refund',
      method: 'post',
      data: { id }
    })
  },
  Delete(data) {
    return request({
      url: url + 'delete',
      method: 'post',
      data
    })
  },
  Summary(data) {
    return request({
      url: url + 'summary',
      method: 'post',
      data
    })
  },
  onlineExcel(data) {
    return request({
      url: url + 'onlineExcel',
      method: 'post',
      data
    })
  },
  getJhDepartment() {
    return request({
      url: '/front/department/getJhDepartmentTree',
      method: 'post'
    })
  }
}
export default api
